import './App.css';
import { Button, ButtonGroup} from 'react-bootstrap';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component } from 'react'; 

import 'sf-font'; 
import Web3 from 'web3';
//import { walletconnect } from 'web3modal/dist/providers/connectors';
 

const $ = window.$;

var tx_pending = false;

var CONFIG = null;
var ABI = null;
var account = null;
var nftcontract = null;

var defSymbol = null;
var totalGasLimit = null;

var walletBalanceAPI = null;
var scannerAPI = null;
var balanceAPI = null;
var resultAPICall = null;
var CONFIGWhitelist = null;

var nitroBCH;
var nftName = null;

var stakingnftcontract = null;
var rewardcontract = null;
var nitrocontract = null;
var nftlevelcontract = null;

var nitroBCHcontract = null;
var nitroBCH_address = null;

var stakingnft_address = null;
var nitroboost_address = null;

var scannercontract = null; 
var scanneraddress = null; 
 
var short_acct;

var web3 = null; 

var rewardInDetail = false;
var vaultSelected = null;

var amtNitro = 0;

var isLoading = false;
var isRendering = false; 

var userStakes = new Array();
var isWalletConnected;


class App extends Component {
  constructor() {
    super();
    this.state = {
      width: window.innerWidth,
    };

  }

  componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }
  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  /* componentDidUpdate(){ 

  } */
  
 
  render() {
    const { width } = this.state;
    const isMobile = width <= 900;

    let nftcost = '';
  
    const sleep = (milliseconds) => {
      return new Promise(resolve => setTimeout(resolve, milliseconds))
    }
  
    function delay() { 
      return new Promise(resolve => setTimeout(resolve, 300));
    }
 
    async function connectwallet() {
      try {
        if (window.ethereum) {

          const configResponse = await fetch("/config/config.json", {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          });
          CONFIG = await configResponse.json();

          web3 = new Web3(window.ethereum);


          await window.ethereum.send('eth_requestAccounts');
          var accounts = await web3.eth.getAccounts();
          account = accounts[0];

          const networkId = await window.ethereum.request({
            method: "net_version",
          });

          if ((networkId == CONFIG.MAINNET.ID) || (networkId == CONFIG.TESTNET.ID)) {

            const abiResponse = await fetch("/config/abi.json", {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
              },
            });
            ABI = await abiResponse.json();

            var abiWhitelist = null;
            //nitroBCH = CONFIG.NitroBCH.PRICE;
            //console.log("nitroBCH", nitroBCH);

            if (networkId == CONFIG.MAINNET.ID) {
              defSymbol = CONFIG.MAINNET.SYMBOL;
              totalGasLimit = CONFIG.MAINNET.GAS_LIMIT;
              scannerAPI = CONFIG.MAINNET.API;
              balanceAPI = CONFIG.MAINNET.BALANCE;
              walletBalanceAPI = CONFIG.MAINNET.WALLET;
              rewardcontract = await ABIFetch(web3, ABI.TOKENREWARD.ABI, CONFIG.MAINDAPP.TOKENREWARD);
              stakingnftcontract = await ABIFetch(web3, ABI.STAKING.ABI, CONFIG.MAINDAPP.STAKING);
              nitrocontract = await ABIFetch(web3, ABI.NITROBOOST.ABI, CONFIG.MAINDAPP.NITROBOOST);
              nftlevelcontract = await ABIFetch(web3, ABI.RUSHLEVEL.ABI, CONFIG.MAINDAPP.LEVELING);
              scannercontract =  await ABIFetch(web3, ABI.SCANNER.ABI, CONFIG.MAINDAPP.SCANNER);

              nitroBCHcontract =  await ABIFetch(web3, ABI.NITROBCH.ABI, CONFIG.MAINDAPP.NITROBCH);
              nitroBCH_address = CONFIG.MAINDAPP.NITROBCH;

              stakingnft_address = CONFIG.MAINDAPP.STAKING;
              nitroboost_address = CONFIG.MAINDAPP.NITROBOOST;  
              scanneraddress = CONFIG.MAINDAPP.SCANNER;

              abiWhitelist = await fetch("/config/whitelisted.json", {
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                },
              });
              CONFIGWhitelist = await abiWhitelist.json();

            }
            else if (networkId == CONFIG.TESTNET.ID) {  
              defSymbol = CONFIG.TESTNET.SYMBOL;
              totalGasLimit = CONFIG.TESTNET.GAS_LIMIT;
              scannerAPI = CONFIG.TESTNET.API;
              balanceAPI = CONFIG.TESTNET.BALANCE;
              walletBalanceAPI = CONFIG.TESTNET.WALLET;
              rewardcontract = await ABIFetch(web3, ABI.TOKENREWARD.ABI, CONFIG.TESTDAPP.TOKENREWARD);
              stakingnftcontract = await ABIFetch(web3, ABI.STAKING.ABI, CONFIG.TESTDAPP.STAKING);
              nitrocontract = await ABIFetch(web3, ABI.NITROBOOST.ABI, CONFIG.TESTDAPP.NITROBOOST);
              nftlevelcontract = await ABIFetch(web3, ABI.RUSHLEVEL.ABI, CONFIG.TESTDAPP.LEVELING);
              scannercontract =  await ABIFetch(web3, ABI.SCANNER.ABI, CONFIG.TESTDAPP.SCANNER);
 
              nitroBCHcontract =  await ABIFetch(web3, ABI.NITROBCH.ABI, CONFIG.TESTDAPP.NITROBCH);
              nitroBCH_address = CONFIG.TESTDAPP.NITROBCH;

              stakingnft_address = CONFIG.TESTDAPP.STAKING;
              nitroboost_address = CONFIG.TESTDAPP.NITROBOOST;
              scanneraddress = CONFIG.TESTDAPP.SCANNER;

              abiWhitelist = await fetch("/config/testwhitelisted.json", {
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                },
              });
              CONFIGWhitelist = await abiWhitelist.json();

            }

            onGoingNotif("");

            var sWallBal = document.getElementById("wallBal");
            sWallBal.style.width = "100px";
            sWallBal.innerHTML = await WalletBalance(account);

            resultAPICall = await APICall(account, scannerAPI); 

            await listNFTFilter(resultAPICall); 

            document.getElementById('unstaked-nft-container').innerHTML="";
            const DivContainer = document.getElementById('staked-nft-container');
            DivContainer.innerHTML = ""; 

            await LoadVaults('staked-nft-container'); 


            var timer = setInterval(function () {
              if (tx_pending==false){ 
                CheckRewards();
              }
            }, 5000);
            await LoadVaultInfo();

            //localStorage.setItem('isWalletConnected', true);
            isWalletConnected = true;
          }

          else {
            var z = window.confirm("Wrong network!");
          }

        }
      }
      catch (ex) {
        console.log('connectwallet - SeeError - ' + ex);
        isWalletConnected = false;
      }
    }

    async function VaultRewardToken(delegate){
      var vaultrewarder = await ABIFetch(web3, ABI.REWARDER.ABI, delegate); 
      var rewardToken = await vaultrewarder.methods.rewardToken().call(); 
      var _rewardcontract = await ABIFetch(web3, ABI.TOKENREWARD.ABI, rewardToken);
      return _rewardcontract;
    }

    async function LoadVaultInfo(){
      try{
        var tbl = document.getElementById('tblvaultInfo');
        tbl.innerHTML="";

        var thd = document.createElement('thead');
        thd.className = "thead-light";
        tbl.appendChild(thd);

        var _tr = document.createElement('tr');
        var _th1 = document.createElement('th');
        _th1.scope = 'col';
        _th1.innerHTML = 'Collection';
        _tr.appendChild(_th1)

        var _th2 = document.createElement('th');
        _th2.scope = 'col';
        _th2.innerHTML = 'Rewards Per Day';
        _tr.appendChild(_th2)

        thd.appendChild(_tr);

        var counter_vault = 0;

        var _tbody = document.createElement('tbody');
        var _vault = await stakingnftcontract.methods.Vault(counter_vault).call();
        while (_vault.active) { 
          var _rewardcontract;
          if (_vault.delegate != stakingnft_address){
            _rewardcontract = await VaultRewardToken(_vault.delegate); 
          }
          else{
            _rewardcontract = await ABIFetch(web3, ABI.TOKENREWARD.ABI, _vault.tokenReward);
          }
          var _rewardSymbol = await _rewardcontract.methods.symbol().call(); 

          var _tbody_tr = document.createElement('tr');

          var _tbody_td1 = document.createElement('td');
  
          var short_vault = (_vault.vaultName.length > 20 ? _vault.vaultName.substring(0, 20) + "..." : _vault.vaultName);
          _tbody_td1.innerHTML = short_vault;  
          _tbody_tr.appendChild(_tbody_td1);

          var _tbody_td2 = document.createElement('td');
          _tbody_td2.class='amount'; 
 
          if (_vault.reqBooster == false){ 
            _tbody_td1.className="textGlow";  
            _tbody_td2.className="textGlow";  
          }

          var _span1 = document.createElement('span');
          _span1.class = "amount";
          _span1.innerHTML=Web3.utils.fromWei(_vault.rewardRate) + " ";

          var _span2 = document.createElement('span');
          _span2.class = "currency";
          _span2.innerHTML = _rewardSymbol;

          _tbody_td2.appendChild(_span1);
          _tbody_td2.appendChild(_span2);

          _tbody_tr.appendChild(_tbody_td2);

          _tbody.appendChild(_tbody_tr);

          counter_vault += 1;
          var _vault = await stakingnftcontract.methods.Vault(counter_vault).call();

        }    
        tbl.appendChild(_tbody);
      }
      catch(ex){
        console.log(ex);
      } 
    }
 

    async function RefreshCollection() {      
      if (account==null){
        return;
      }
      var nftCol = document.getElementById("nftFilter").value;  
      resultAPICall = await APICall(account, scannerAPI);
      await listNFT(account, 'unstaked-nft-container', resultAPICall, nftCol);
    }

    async function APICall(account, API) {
      var theURL = API + "=" + account;
      var apiResponse = await fetch(theURL);
      var jsonParsedContents = await apiResponse.json();

      return jsonParsedContents;
    }

    async function APIBalanceCall(_account, _CA, API) {
      var theURL = API.replace('@CONTRACTADDRESS', _CA).replace('@ACCOUNT', _account);
      var apiResponse = await fetch(theURL);
      var jsonParsedContents = await apiResponse.json();

      return jsonParsedContents;
    }

    async function WalletBalance(_account) {
      var theURL = walletBalanceAPI.replace('@ACCOUNT', _account);
      var apiResponse = await fetch(theURL);
      var jsonParsedContents = await apiResponse.json();

      var earnedrwd = Web3.utils.fromWei(jsonParsedContents.result);
      var rewards = Number(earnedrwd).toFixed(3);

      return '  ' + rewards + ' ' + defSymbol;
    }

    async function claimVault(_vaultId) {
      try {
        await onGoingNotif("Pending..."); 
        var _vault = await stakingnftcontract.methods.Vault(_vaultId).call();

        var _tkIDs = [];

        var nftcontract = await ABIFetch(web3, ABI.ERC721.ABI, _vault.nftAddress);        
        var balanceOfOwner = await nftcontract.methods.balanceOf(stakingnft_address).call(); 
        //console.log("balanceOfOwner", balanceOfOwner);

        for (let j = 0; j < balanceOfOwner; j++) {
          var _tkID = await nftcontract.methods.tokenOfOwnerByIndex(stakingnft_address, j).call();
          var _nftStakeInfo = await stakingnftcontract.methods.nftStake(_vaultId, _tkID).call();
          if (_nftStakeInfo.owner == account) { 
            _tkIDs.push(_tkID);
          }

          if (_tkIDs.length == userStakes[_vaultId][0]){
            break;
          }

          //console.log("_tkIDs", _tkIDs);
        }
  
        await stakingnftcontract.methods.claim(_vaultId, _tkIDs).send({ from: account });
        await refreshDAppView();

      }
      catch (ex) {
        console.log('claimVault');
        console.log(ex);
      }
      await onGoingNotif(""); 
    } 

    async function modifyVault(_vaultId, _vaultContract) {
      try {
        await UnstakeMenuControl(true, true, true);

        var tab_container = "staked-nft-container";
        document.getElementById('staked-nft-container').innerHTML = "";
 
        rewardInDetail = true;

        vaultSelected = await scannercontract.methods.getVaultAddress(_vaultId).call();
        nftcontract = await ABIFetch(web3, ABI.ERC721.ABI, vaultSelected);
        await renderNFT(tab_container, nftcontract, vaultSelected, account, true);
 
      }
      catch (ex) {
        console.log(ex);
      }
    }


    async function onGoingNotif(msg) {
      var btnConnect = document.getElementById('btnConnect');
      if (msg == "") {
        try {
          hideCover();
          tx_pending=false;
        }
        catch (ex) {
        }

        short_acct = account.substring(0, 6) + "...." + account.substring(account.length - 4, account.length);
        var img = document.createElement('img');
        img.className = "ml-3";
        img.setAttribute("src", 'chef.svg'); 
        btnConnect.innerHTML = short_acct;
        btnConnect.appendChild(img);
      }
      else {
        showCover();
        btnConnect.innerHTML = "";
        btnConnect.innerHTML = msg; 
      }
    }

    async function unstakeVault(_vaultId) {
      try {
        await onGoingNotif("Pending...");

        var _vault = await stakingnftcontract.methods.Vault(_vaultId).call();

        var _tkIDs = [];
        resultAPICall = await APIBalanceCall(stakingnft_address, _vault.nftAddress, balanceAPI);
        nftcontract = await ABIFetch(web3, ABI.ERC721.ABI, _vault.nftAddress);

        for (let i = 0; i < resultAPICall.result; i++) {
          var _tkID = await nftcontract.methods.tokenOfOwnerByIndex(stakingnft_address, i).call();
          var _nftStakeInfo = await stakingnftcontract.methods.nftStake(_vaultId, _tkID).call();
          if (_nftStakeInfo.owner == account) {
            _tkIDs.push(_tkID);
          }
        }
        await stakingnftcontract.methods.unstake(_vaultId, _tkIDs).send({ from: account });
        await refreshDAppView();
      }
      catch (ex) {
        console.log('unstakeVault');
        console.log(ex);
      }
      await onGoingNotif("");
    }

    async function GetNFTLevel(_vaultId, _tkID){   
      try{
        var nftLevel = await nftlevelcontract.methods.getNFTLevel(_vaultId, _tkID).call(); 
        if (nftLevel != "undefined") {
          return `Lv ${nftLevel}`;
        }         
      } 
      catch(ex){
        return "";
      }     
    }

    async function CheckRewards() {
      /* if (isWalletConnected == false){
        await connectwallet();
      } */
      if (isLoading || isRendering) {
        return;
      }
      isLoading = true;

      var _stakeVaults = new Array();
      var _vaultId;
      var _symbol = await rewardcontract.methods.symbol().call();

      try {
        var btnConnect = document.getElementById('btnConnect');
        if (!btnConnect.innerHTML.includes(short_acct)) {
          return;
        }
        var sWallBal = document.getElementById("wallBal");
        sWallBal.innerHTML = await WalletBalance(account);
 
        var totalVaults = await scannercontract.methods.getTotalVault().call();

        if (rewardInDetail == false) {

          for (let i = 0; i < totalVaults; i++) {
            //console.log("totalGasLimit", totalGasLimit)
            var isAcctHasStakeOnVault = await scannercontract.methods.isAcctHasStakeOnVault(account, i).call(); //({gasLimit: String(totalGasLimit)});
            if (isAcctHasStakeOnVault) {
              _stakeVaults.push(i); 
            }
          }

          for (let i = 0; i < _stakeVaults.length; i++) {
            _vaultId = _stakeVaults[i];  
            var contractAdress = await scannercontract.methods.getVaultAddress(_vaultId).call();  

            var divPend = document.getElementById("Pending" + contractAdress);
            var _pendingRewards = 0;

            if (divPend != null) {
              var result = await stakingnftcontract.methods.pendingRewardByAccount(_vaultId, account).call();
              const { 0: _pend, 1: _stakeCount } = result;

              userStakes.push([_vaultId, _stakeCount]);
              //console.log("userStakes", userStakes);

              var earned = String(_pend).split(",")[0];
              var earnedrwd = Web3.utils.fromWei(earned);
              _pendingRewards = Number(earnedrwd).toFixed(2);

              //Custom Reward Token
              var _vault = await stakingnftcontract.methods.Vault(_vaultId).call();
              if (_vault.delegate != stakingnft_address) {
                var _rewardcontract = await VaultRewardToken(_vault.delegate);
                _symbol = await _rewardcontract.methods.symbol().call();
              }
              divPend.innerHTML = `Total: ${_stakeCount} | <h6 class='pendingRewards'> Earn: ${_pendingRewards} ${_symbol} </h6>`;
            }
          }
        }
        else if (rewardInDetail == true) {
          try { 
            _vaultId = await stakingnftcontract.methods.getVaultId(vaultSelected).call(); 
            _pendingRewards = 0; 

            //Custom Reward Token
            var _vault = await stakingnftcontract.methods.Vault(_vaultId).call();
            if (_vault.delegate != stakingnft_address) {
              var _rewardcontract = await VaultRewardToken(_vault.delegate);
              _symbol = await _rewardcontract.methods.symbol().call();
            }

            var tokenIds = await getTokenIDs(_vaultId, account);
            for (var i = 0; i < tokenIds.length; i++) {
              var _tkID = tokenIds[i];
              var _pend = await stakingnftcontract.methods.pendingReward(_vaultId, _tkID).call();
              var earned = String(_pend).split(",")[0];
              var earnedrwd = Web3.utils.fromWei(earned);
              var rewards = Number(earnedrwd).toFixed(4);
              _pendingRewards = Number(rewards).toFixed(4);

              //console.log("Earn: " + _pendingRewards + " " + _symbol);

              const objNFTPend = document.getElementById("att" + _vaultId + _tkID);
              objNFTPend.innerHTML = "";
              objNFTPend.innerHTML = "Earn: " + _pendingRewards + " " + _symbol;


              var nftLevel = await GetNFTLevel(_vaultId, _tkID);
              const objNFTLvl = document.getElementById("lvl" + _vaultId + _tkID);
              objNFTLvl.innerHTML = "";
              objNFTLvl.innerHTML = nftLevel;

              var _nitroStakeRemaining;
              try {
                const result = await stakingnftcontract.methods.nitroStakeRemaining(_vaultId, _tkID).call();

                const { 0: nitroID, 1: limit, 2: remain } = result;
                _nitroStakeRemaining = remain;

                var _nitroEquipped;
                var _multipower;
                var _powerFloat;
                var nftLvlBonus;
                var _lvlFloat;

                _nitroEquipped = await stakingnftcontract.methods.getEquippedNitro(_vaultId, _tkID).call();
                if (_nitroEquipped > 0) {
                  _multipower = await stakingnftcontract.methods.getNitroPower(_vaultId, _nitroEquipped).call();
                  _powerFloat = parseFloat(_multipower + '.00') / 100;
                  nftLvlBonus = await nftlevelcontract.methods.getLevelBonus(_vaultId, _tkID).call();
                  _lvlFloat = parseFloat(nftLvlBonus + '.00') / 100;
                  _powerFloat = _powerFloat * _lvlFloat;
 
                  if (_nitroStakeRemaining > 0) {
                    var _glowNum = await nitrocontract.methods.getRareType(nitroID).call();

                    var inSec = (_nitroStakeRemaining / 86400) * 24 * 60 * 60;

                    var seconds = Number(inSec);
                    var d = Math.floor(seconds / (3600 * 24));
                    var h = Math.floor(seconds % (3600 * 24) / 3600);
                    var m = Math.floor(seconds % 3600 / 60);
                    var s = Math.floor(seconds % 60);

                    var dDisplay = d > 0 ? d + (d == 1 ? " day, " : " days, ") : "";
                    var hDisplay = h > 0 ? h + (h == 1 ? ":" : ":") : "";
                    var mDisplay = m > 0 ? m + (m == 1 ? ":" : ":") : "";
                    var sDisplay = s > 0 ? s + (s == 1 ? "" : "") : "";
                    var remainingBurn = dDisplay + hDisplay + mDisplay + sDisplay;
 
                    document.getElementById("att2" + _vaultId + _tkID).innerHTML = "<span class='font-awesome-icons rocket'></span> " + remainingBurn + ' left'
                      + '</br> x' + _powerFloat.toFixed(2);
                    document.getElementById("nft" + _vaultId + "|" + _tkID).className = "nftCard Equipped" + _glowNum;

                  }
                  else {
                    document.getElementById("att2" + _vaultId + _tkID).innerHTML = 'x' + _powerFloat.toFixed(2);
                  } 
                }
              }
              catch (ex3) {
                console.log("Error in RewardInquiry - Detailed: ", ex3);
              }
            }
          }
          catch (ex2) {
            console.log(ex2);
          }
        }
      }
      catch(ex1){
        console.log(ex1);
      }
      isLoading = false; 
    }
 
    async function UnstakeMenuControl(btnBack, btnClaim, btnUnstake) {
      var _btnBack = document.getElementById("btnBack");
      var _btnClaim = document.getElementById("btnClaimSelected");
      var _btnUnstake = document.getElementById("btnUnstakeSelected");

      if ((btnBack == false) && (btnClaim == false) && (btnUnstake == false)) {
        document.getElementById("btnUnstakeMenu").style.display = "none";
      }
      else {
        document.getElementById("btnUnstakeMenu").style.display = "";
      }

      if (btnBack == false) {
        _btnBack.setAttribute("disabled", "disabled");
        _btnBack.classList.add("disabled");
      }
      else {
        _btnBack.removeAttribute('disabled');
        _btnBack.classList.remove("disabled");
      }

      if (btnClaim == false) {
        _btnClaim.setAttribute("disabled", "disabled");
        _btnClaim.classList.add("disabled");
      }
      else {
        _btnClaim.removeAttribute('disabled');
        _btnClaim.classList.remove("disabled");
      }

      if (btnUnstake == false) {
        _btnUnstake.setAttribute("disabled", "disabled");
        _btnUnstake.classList.add("disabled");
      }
      else {
        _btnUnstake.removeAttribute('disabled');
        _btnUnstake.classList.remove("disabled");
      }
    }
  
    async function getTokenIDs(_vaultId, _account) {
      var tokenIds = await scannercontract.methods.getStakeInVaultAccount(_vaultId, _account).call();
      //console.log("debug");
      var tkns = new Array();
      for (let j = 0; j < tokenIds.length; j++) {
        if (tokenIds[j] > 0) {
          tkns.push(tokenIds[j]);
        }
      }
      return tkns;
    }
  
    async function LoadVaults(tab_container){
      if (account == null) {
        return;
      }
      if(isRendering){
        return;
      }
      isRendering = true;
      onGoingNotif("Rendering NFTs...");
 
      await UnstakeMenuControl(false, false, false);

      const DivContainer = document.getElementById(tab_container);
      DivContainer.innerHTML = "";

      if (width < 830) {
        DivContainer.className = "nft_card_view1";
      }
      else if (width>=830 && width<1000){
        DivContainer.className = "nft_card_view2";
      }
      else if (width>=1000 && width<1300){
        DivContainer.className = "nft_card_view3";
      }
      else if(width>=1300){
        DivContainer.className = "nft_card_view4";
      }

      var _vaultId;
      var tokenIds;
      var totalVaults = await scannercontract.methods.getTotalVault().call();
      var isAcctHasStakeOnVault;
      var _stakeVaults = new Array();
      var contractAdress;
      var usrCurrVault;
      var _vaultContract;

      for (let i=0; i<totalVaults; i++){
        isAcctHasStakeOnVault = await scannercontract.methods.isAcctHasStakeOnVault(account, i).call();
        if (isAcctHasStakeOnVault){
          _stakeVaults.push(i);
          usrCurrVault = i;
        }
      }
      //console.log("_stakeVaults", _stakeVaults);
 
 
      if (_stakeVaults.length==1){
        rewardInDetail = true;
        _vaultContract = await scannercontract.methods.getVaultAddress(usrCurrVault).call();

        await modifyVault(usrCurrVault, _vaultContract);
      }
      else{
        for (let i=0; i<_stakeVaults.length; i++){
          _vaultId = _stakeVaults[i];
            var _vaultInfo = await stakingnftcontract.methods.Vault(_vaultId).call();

          if (_vaultInfo.active) {
            contractAdress = _vaultInfo.nftAddress;
            nftcontract = await ABIFetch(web3, ABI.ERC721.ABI, contractAdress);
            var nftName = await nftcontract.methods.name().call();

            tokenIds = await getTokenIDs(_vaultId, account);

            var _tkID = 0;
            for (var j = 0; j < tokenIds.length; j++) {
              _tkID = tokenIds[j];
              if (_tkID == 0) {
                continue;
              }

              let tokenMetadataURI = await nftcontract.methods.tokenURI(_tkID).call();
              if (tokenMetadataURI.startsWith("ipfs://")) {
                tokenMetadataURI = `https://ipfs.io/ipfs/${tokenMetadataURI.split("ipfs://")[1]}`;
              }
              else if (tokenMetadataURI.startsWith("https://gateway.pinata.cloud/ipfs/")) {
                tokenMetadataURI = tokenMetadataURI.replace(`https://gateway.pinata.cloud/ipfs/`, ``);
                tokenMetadataURI = `https://ipfs.io/ipfs/${tokenMetadataURI}${1}`;
              }
              var tokenMetadata = await fetch(tokenMetadataURI).then((response) => response.json());

              (async function () {
                const cardVault = document.createElement('div');
                cardVault.className = "vault_container";
                cardVault.id = contractAdress;
                cardVault.value = _vaultId;

                const cardVaultCard = document.createElement('div');
                cardVaultCard.className = "vault_card";
                if (isMobile) {
                  cardVaultCard.className = "vault_card-mobile";
                }

                const cardVaultFront = document.createElement('div');
                cardVaultFront.className = "vault_front parent";
                if (isMobile) {
                  cardVaultFront.className = "vault_front-mobile parent";
                }

                var imgNFT
                if (tokenMetadata["image"].includes("mp4")) {
                  imgNFT = document.createElement('video');

                  const vid_source = document.createElement("source");

                  if (tokenMetadata["image"].startsWith("ipfs://")) {
                    vid_source.setAttribute("src", `https://ipfs.io/ipfs/${tokenMetadata["image"].split("ipfs://")[1]}`);
                  }
                  else {
                    vid_source.setAttribute("src", tokenMetadata["image"]);
                  }
                  vid_source.setAttribute("type", "video/mp4");
                  imgNFT.appendChild(vid_source);
                }
                else {
                  imgNFT = document.createElement('img');
                  imgNFT.setAttribute("src", '.\loading.gif');

                  var img_src = tokenMetadata["image"];
                  imgNFT.setAttribute("src", img_src);
                }
                imgNFT.setAttribute("alt", contractAdress);

                const cardVaultDetails = document.createElement('div');
                cardVaultDetails.className = "vault_details";

                const cardH4 = document.createElement('h5');
                cardH4.style.color = "white";
                cardH4.className = "VaultName";
                cardH4.innerHTML = (nftName.length > 15 ? nftName.substring(0, 10) + "..." : nftName) + " Vault";

                const cardSpan = document.createElement('span');
                cardSpan.className = "spanX"
                cardSpan.id = "Pending" + contractAdress;
                cardSpan.style.color = "white";
                cardSpan.innerHTML = "";

                cardVaultDetails.appendChild(cardH4);
                cardVaultDetails.appendChild(cardSpan);

                cardVaultFront.appendChild(imgNFT);
                cardVaultFront.appendChild(cardVaultDetails);

                const cardVaultBack = document.createElement('div');
                cardVaultBack.className = "vault_back";

                var _tbl = document.createElement('table');
                var _tr = document.createElement('tr');
                _tbl.appendChild(_tr);

                var _attrdiv = document.createElement('div');
                var nft_dtl = document.createElement('h6');
                var _nftName = await nftcontract.methods.name().call();
                var _symbl = await nftcontract.methods.symbol().call();
                var _supply = await nftcontract.methods.totalSupply().call();
                nft_dtl.innerHTML = "Name: " + _nftName + '</br>Symbol: ' + _symbl + '</br>Total Supply: ' + _supply;
                _attrdiv.appendChild(nft_dtl);
                _tbl.appendChild(_attrdiv);

                var _tr2 = document.createElement('tr');
                _tr2.className = "vault_back_att";

                const cardButtonClaim = document.createElement('a');
                cardButtonClaim.style.color = "white";
                cardButtonClaim.innerHTML = "Claim All ";
                cardButtonClaim.className = "back_attr";
                cardButtonClaim.onclick = function () { claimVault(cardVault.value); }

                const cardButtonUnstake = document.createElement('a');
                cardButtonUnstake.style.color = "white";
                cardButtonUnstake.innerHTML = "Unstake All ";
                cardButtonUnstake.className = "back_attr";
                cardButtonUnstake.onclick = function () { unstakeVault(cardVault.value); }

                const cardButtonModify = document.createElement('a');
                cardButtonModify.style.color = "white";
                cardButtonModify.innerHTML = "Modify";
                cardButtonModify.className = "back_attr";
                cardButtonModify.onclick = function () { modifyVault(cardVault.value, cardVault.id); }

                _tr2.appendChild(cardButtonClaim);
                _tr2.appendChild(cardButtonUnstake);
                _tr2.appendChild(cardButtonModify);

                _tbl.appendChild(_tr2);
                cardVaultBack.appendChild(_tbl);
 
                cardVaultCard.appendChild(cardVaultFront);
                cardVaultCard.appendChild(cardVaultBack);

                cardVault.appendChild(cardVaultCard);

                DivContainer.appendChild(cardVault);
              })(); 
              break; 
            }
          }
        }
      } 
      isRendering = false;
      onGoingNotif(""); 
    }
 
    async function listNFT(_account, tab_container, jsonParsedContents, filterList) {
      try {
        var userNFTHold = 0;
        var btnStakeAll = document.getElementById("btnStakeAll");
        var btnStakeSelected = document.getElementById("btnStakeSelected");

        document.getElementById(tab_container).innerHTML = ""; 

        if (filterList == "All" || filterList == "All Owned NFT") {
          btnStakeAll.setAttribute('disabled', 'disabled');
          btnStakeSelected.setAttribute('disabled', 'disabled');

          //Populate List NFT 
          if (jsonParsedContents != null) {  
            for (var key in CONFIGWhitelist.NFT) {
              for (let i = 0; i < jsonParsedContents.result.length; i++) { 

                if (jsonParsedContents.result[i].type == "ERC-721") {
                  if (CONFIGWhitelist.NFT[key].ADDRESS == jsonParsedContents.result[i].contractAddress) {
                    nftcontract = await ABIFetch(web3, ABI.ERC721.ABI, jsonParsedContents.result[i].contractAddress);
                    userNFTHold = userNFTHold + (await renderNFT(tab_container, nftcontract, jsonParsedContents.result[i].contractAddress, _account));  //myHoldNFT
                    break;
                  }
                }
              }
            }

            //All other ERC-721
            var ctr;
            for (let i = 0; i < jsonParsedContents.result.length; i++) {
                if (jsonParsedContents.result[i].type == "ERC-721" && nitroboost_address.toUpperCase() != jsonParsedContents.result[i].contractAddress.toUpperCase()) {
                ctr = 0;
                for (var key in CONFIGWhitelist.NFT) {
                  if (CONFIGWhitelist.NFT[key].ADDRESS != jsonParsedContents.result[i].contractAddress) {
                    ctr++;
                  }
                }
                if (ctr >= CONFIGWhitelist.NFT.length) {
                  nftcontract = await ABIFetch(web3, ABI.ERC721.ABI, jsonParsedContents.result[i].contractAddress);  
                  userNFTHold = userNFTHold + (await renderNFT(tab_container, nftcontract, jsonParsedContents.result[i].contractAddress, _account));  //myHoldNFT
                }
              }
            }
          }
        }
        else {
          nftcontract = await ABIFetch(web3, ABI.ERC721.ABI, filterList); 
          userNFTHold = userNFTHold + (await renderNFT(tab_container, nftcontract, filterList, _account));   
          btnStakeAll.removeAttribute('disabled');
          btnStakeSelected.removeAttribute('disabled');
        }

        if (userNFTHold == 0) {
          ShowNFTError("NO NFT SCANNED.");
        }
      }

      catch (ex) {
        console.log('listNFT - SeeError - ' + ex);
        ShowNFTError(ex);
      }
    }

    async function ABIFetch(web3, _abi, _address) {
      var _contract;
      var ABIResponse;

      ABIResponse = await fetch(_abi, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      const ABIJSON = await ABIResponse.json();
      _contract = new web3.eth.Contract(ABIJSON, _address);
      return _contract;
    }

    async function listNFTFilter(jsonParsedContents) {
      var lstNFT = document.getElementById("nftFilter");
      lstNFT.innerHTML = "";

      for (let i = 0; i < jsonParsedContents.result.length; i++) {
        if (i == 0) {
          var z_1 = document.createElement("option");
          z_1.setAttribute("value", "All");
          var t_1 = document.createTextNode("All Owned NFT");

          z_1.appendChild(t_1);
          lstNFT.appendChild(z_1);
        }

        if (jsonParsedContents.result[i].type == "ERC-721" && jsonParsedContents.result[i].balance > 0 && nitroboost_address.toUpperCase() != jsonParsedContents.result[i].contractAddress.toUpperCase()) {
          var z = document.createElement("option");
          z.setAttribute("value", jsonParsedContents.result[i].contractAddress);

          var t = document.createTextNode(jsonParsedContents.result[i].name + ": " + jsonParsedContents.result[i].balance);
          z.appendChild(t);

          lstNFT.appendChild(z);
        } 
      }
    }

    async function fetchNFTImage(nftcontract, _account, tkID, _caller) {
      var tokenMetadata = null;
      var tokenMetadataURI;
      try {
        tokenMetadataURI = await nftcontract.methods.tokenURI(tkID).call();  

        if (tokenMetadataURI.startsWith("ipfs://")) {
          tokenMetadataURI = `https://ipfs.io/ipfs/${tokenMetadataURI.split("ipfs://")[1]}`;
        }
        else if (tokenMetadataURI.startsWith("https://gateway.pinata.cloud/ipfs/")) {
          tokenMetadataURI = tokenMetadataURI.replace(`https://gateway.pinata.cloud/ipfs/`, ``);
          tokenMetadataURI = `https://ipfs.io/ipfs/${tokenMetadataURI}${tkID}`;
        }
 
 
        //tokenMetadata = await fetch(tokenMetadataURI).then((response) => response.json());
        if (await isImgUrl(tokenMetadataURI)){ 
          tokenMetadata = await loadDefaultURI(); 
          tokenMetadata["image"] = tokenMetadata["image"].replace(tokenMetadata["image"], tokenMetadataURI); 
        }
        else{
          tokenMetadata = await fetch(tokenMetadataURI).then((response) => response.json());
        }

      }
      catch (ex) {
        //var uri = "https://raw.githubusercontent.com/cryptobugnft/nitro/main/tank/InvalidURI.json";
        tokenMetadata = await loadDefaultURI(); //fetch(uri).then((response) => response.json());
        console.log('Caller - ' + _caller, ex);
      } 
      return tokenMetadata; 
    }

    async function loadDefaultURI(){
      var uri = "https://raw.githubusercontent.com/cryptobugnft/nitro/main/tank/InvalidURI.json";
      return await fetch(uri).then((response) => response.json());
    }

    async function isImgUrl(url) {
      return fetch(url, {method: 'HEAD'}).then(res => {
        return res.headers.get('Content-Type').startsWith('image')
      })
    }
  
    async function renderNFT(tab_container, nftcontract, contractAdress, _account, isStaked){
      var nftName = await nftcontract.methods.name().call();
      var balanceOfOwner;  

      var tokenIds;
      if (isStaked){        
        var _vaultId = await stakingnftcontract.methods.getVaultId(contractAdress).call();
        tokenIds = await getTokenIDs(_vaultId, _account); 
        balanceOfOwner = tokenIds.length;
      }
      else{
        balanceOfOwner = await nftcontract.methods.balanceOf(_account).call();  
      }

      var nftContainer = document.getElementById(tab_container); 
      var nftCard;
        var nftTable;
      var nftMedia;
        var nftTD;
          var nftLvl;
          var nftChk;
          var nftLbl;
          var nftImg;
      var nftAtt;
        var nftAttTD;
          var nftTxt1;
          var nftTxt2;
          var nftBtn;
          var nftTxt3;
      try{

        if (width>=400 && width<850){
          nftContainer.className = "nft_card_view2";
        }
        else if (width>=850 && width<1000){
          nftContainer.className = "nft_card_view3";
        }
        else if (width>=1000 && width<1300){
          nftContainer.className = "nft_card_view4";
        }
        else if(width>=1300){
          nftContainer.className = "nft_card_view5";
        }

        onGoingNotif("Loading...");
        for (let i = 0; i < balanceOfOwner; i++){
          (async function (){
            var tkID;

            if (isStaked){
              if (tokenIds[i]==0){
                return;
              }
              tkID = tokenIds[i];
            }
            else{
              tkID = await nftcontract.methods.tokenOfOwnerByIndex(_account, i).call();
            }
            
            const tokenMetadata = await fetchNFTImage(nftcontract, _account, tkID, 'renderNFT');  
            const _vaultId = await stakingnftcontract.methods.getVaultId(contractAdress).call();
             

            nftCard = document.createElement("div");
            nftCard.id = "nft" + _vaultId + "|" + tkID;
            nftCard.className = "nftCard";

            nftTable = document.createElement("TABLE");
            nftTable.setAttribute("id", "tbl" + _vaultId + tkID);
            nftCard.appendChild(nftTable);

            nftMedia = document.createElement("TR");
            nftMedia.className = "nftMedia";
            nftTable.appendChild(nftMedia);

            nftTD = document.createElement("TD");
            nftTD.className = "nftTD";
            nftMedia.appendChild(nftTD);

            nftLvl = document.createElement('h6');
            nftLvl.id = "lvl" + _vaultId + tkID;
            nftLvl.className = "nftLvl";
            nftLvl.innerHTML = ""
            nftTD.appendChild(nftLvl);

            nftChk = document.createElement("INPUT");
            nftChk.setAttribute("type", "checkbox");
            nftChk.id = contractAdress + "_" + tkID;
            nftChk.className = "nftChk";
            nftTD.appendChild(nftChk);
 
            nftLbl = document.createElement("LABEL");
            nftLbl.className = "parent";
            nftLbl.htmlFor = nftChk.id;
            nftTD.appendChild(nftLbl);

            if (tokenMetadata["image"].includes("mp4")) {
              nftImg = document.createElement('video');
              const vid_source = document.createElement("source");
              if (tokenMetadata["image"].startsWith("ipfs://")) {
                vid_source.setAttribute("src", `https://ipfs.io/ipfs/${tokenMetadata["image"].split("ipfs://")[1]}`);
              }
              else {
                vid_source.setAttribute("src", tokenMetadata["image"]);
              }
              vid_source.setAttribute("type", "video/mp4");
              nftImg.appendChild(vid_source);
            }
            else {
              try{
                nftImg = document.createElement('img');
                var _imgFix = tokenMetadata["image"];
                if (_imgFix.startsWith("ipfs://")) {
                  _imgFix = `https://ipfs.io/ipfs/${_imgFix.replace("ipfs://", '')}`
                }
                else if (_imgFix.startsWith("https://cattos.mypinata.cloud/ipfs/")) {
                  _imgFix = _imgFix.replace(`https://cattos.mypinata.cloud/ipfs/`, `https://gateway.ipfs.io/ipfs/`);
                }
                nftImg.setAttribute("src", _imgFix);
              }
              catch(img_er){
                console.log("renderNFT:", img_er);
                nftImg.setAttribute("src", "hidden.jpg");
              }
            }
            nftImg.setAttribute("alt", "Error loading image");
            nftImg.className="nftImg";
            nftLbl.appendChild(nftImg);
             
            nftAtt = document.createElement("TR");
            nftAtt.className = "nftAtt";
            nftTable.appendChild(nftAtt);

            nftAttTD = document.createElement("TD");
            nftAttTD.className = "nftAttTD";
            nftAtt.appendChild(nftAttTD);

 
            nftTxt1 = document.createElement('h6');
            nftTxt1.id = "stk" + _vaultId + tkID;
            nftTxt1.className = "nftTxt1";
            nftTxt1.innerHTML = (nftName.length > 20 ? nftName.substring(0, 20) : nftName) + ` #` + (tkID.length > 5 ? tkID.substring(1, 5) : tkID);
            nftAttTD.appendChild(nftTxt1); 

            nftTxt2 = document.createElement('h6');
            nftTxt2.id = "att" + _vaultId + tkID;
            nftTxt2.className = "nftTxt2";
            nftTxt2.innerHTML = "";
            nftAttTD.appendChild(nftTxt2); 

            nftBtn = document.createElement('button');
            nftBtn.type = "button";
            nftBtn.className = "nftBtn";
            nftBtn.style = "{{ fontFamily: 'SF Pro Display' }}";
            nftBtn.innerHTML = "+";

            if (isStaked){              
              nftBtn.onclick = function () {
                showPrompt(_vaultId + "|" + tkID, function (value) {
                });
              };
            }
            else{              
              nftBtn.onclick = function () {
                showPromptTransfer(_vaultId + "|" + tkID + "|" + contractAdress, function (value) {
                });
              };
            }

            nftAttTD.appendChild(nftBtn); 
 
            nftTxt3 = document.createElement('h6');
            nftTxt3.id = "att2" + _vaultId + tkID;
            nftTxt3.className = "nftTxt3";
            nftTxt3.innerHTML = "";
            nftAttTD.appendChild(nftTxt3); 

            nftContainer.appendChild(nftCard);

          })();
        }
        onGoingNotif("");
        return balanceOfOwner;


      }
      catch(ex){
        ShowNFTError(nftcontract + " - Error on reading token URI");
        console.log("renderNFT", ex);
      }
    }

    async function ShowNFTCard(tab_container, nftcontract, contractAdress, _account, isStaked) {
      var nftName = await nftcontract.methods.name().call();
      var balanceOfOwner = await nftcontract.methods.balanceOf(_account).call();
      var DivContainer = document.getElementById(tab_container);
      var nftcontainer;
      var objNFTPend;
      var objNFTNitroEquip;
      var btnPowerUp;
      var imgNFT;

      var mismatchNFT; 

      try {
        for (let i = 0; i < balanceOfOwner; i++) {
          (async function () {
            try {
              var tkID = await nftcontract.methods.tokenOfOwnerByIndex(_account, i).call();
              const tokenMetadata = await fetchNFTImage(nftcontract, _account, tkID, 'ShowNFTCard');
              var _vaultId = await stakingnftcontract.methods.getVaultId(contractAdress).call();
              var _checkAdd = stakingnftcontract.methods.Vault(_vaultId).call();
              
              mismatchNFT = false;
              if (_checkAdd.nftAddress != contractAdress) {
                mismatchNFT = true;
              }

              var _nftStakeInfo = await stakingnftcontract.methods.nftStake(_vaultId, tkID).call();
              if (isStaked) {
                if (_nftStakeInfo.owner != account) {
                  return;
                }
              }

              nftcontainer = document.createElement("div");
              nftcontainer.id = "nftcontainer_" + _vaultId + "_" + tkID; 
              if (isMobile) {
                nftcontainer.className = "nftcontainer-mobile";
              }
              else {
                nftcontainer.className = "nftcontainer";
              }

              const nftdetail = document.createElement('div');
              nftdetail.id = "nftdetail_" + _vaultId + "_" + tkID;
              nftdetail.className = "nftdetail";

              const chk = document.createElement("INPUT");
              chk.setAttribute("type", "checkbox");
              chk.id = contractAdress + "_" + tkID;
              chk.className = "unlisted";

              const lbl = document.createElement("LABEL");
              lbl.className = "parent";
              lbl.htmlFor = chk.id;

              if (tokenMetadata["image"].includes("mp4")) {
                imgNFT = document.createElement('video');
              }
              else {
                imgNFT = document.createElement('img');
              }
              if (isMobile) {
                imgNFT.className = "image-mobile";
              }
              else {
                imgNFT.className = "image";
              }

              if (tokenMetadata["image"].includes("mp4")) {

                const vid_source = document.createElement("source");
                if (tokenMetadata["image"].startsWith("ipfs://")) {
                  vid_source.setAttribute("src", `https://ipfs.io/ipfs/${tokenMetadata["image"].split("ipfs://")[1]}`);
                }
                else {
                  vid_source.setAttribute("src", tokenMetadata["image"]);
                }

                vid_source.setAttribute("type", "video/mp4");
                imgNFT.appendChild(vid_source);
              }
              else {
                var _imgFix = tokenMetadata["image"];
                if (_imgFix.startsWith("ipfs://")) {
                  _imgFix = `https://ipfs.io/ipfs/${_imgFix.replace("ipfs://", '')}`
                }
                imgNFT.setAttribute("src", _imgFix);
              } 
              imgNFT.setAttribute("alt", "Error loading image");  
 
              var _level = await nftlevelcontract.methods.getNFTLevel(_vaultId, tkID).call();
              if(_level>0){
                _level = "(Lv" + _level + ")"; 
              }
              else{
                _level="";
              }

              const objNFT = document.createElement('h6');
              objNFT.id = "stk" + _vaultId + tkID;
              objNFT.className = "h6nftName";
              objNFT.innerHTML = (nftName.length > 20 ? nftName.substring(1, 20) : nftName) + ` #` + (tkID.length > 5 ? tkID.substring(1, 5) : tkID) + _level;

              lbl.appendChild(imgNFT);
              nftcontainer.appendChild(chk);
              nftcontainer.appendChild(lbl);

              nftdetail.appendChild(objNFT);
              nftcontainer.appendChild(nftdetail);

              /* Begin - For Staked NFT Only */
              if (isStaked) {
 
                objNFTPend = document.createElement('h6');
                objNFTPend.className = "h6pendingEarn";
                objNFTPend.id = "pend" + _vaultId + tkID;
                objNFTPend.innerHTML = "";

                objNFTNitroEquip = document.createElement('h6');
                objNFTNitroEquip.className = "h6nitroEquip";
                objNFTNitroEquip.id = "nitro" + _vaultId + tkID;
                objNFTNitroEquip.innerHTML = "";

                btnPowerUp = document.createElement('button');
                btnPowerUp.type = "button";
                btnPowerUp.className = "powerUpButton";
                btnPowerUp.style = "{{ fontFamily: 'SF Pro Display' }}";
                btnPowerUp.innerHTML = "+";

                btnPowerUp.onclick = function () {
                  showPrompt(_vaultId + "|" + tkID, function (value) {
                  });
                };
                nftdetail.appendChild(objNFTNitroEquip);
                nftcontainer.appendChild(objNFTPend);
                nftcontainer.appendChild(btnPowerUp);
              }
              /* End - For Staked NFT Only */

              /*Put Transfer NFT if Not Staked*/
              else {

                btnPowerUp = document.createElement('button');
                btnPowerUp.type = "button";
                btnPowerUp.className = "transferButton";
                btnPowerUp.style = "{{ fontFamily: 'SF Pro Display' }}";
                btnPowerUp.innerHTML = "+";

                if (mismatchNFT) {
                  btnPowerUp.onclick = function () {
                    showPromptTransfer("-1|" + tkID + "|" + contractAdress, function (value) {
                    });
                  };
                }
                else {
                  btnPowerUp.onclick = function () {
                    showPromptTransfer(_vaultId + "|" + tkID + "|" + contractAdress, function (value) {
                    });
                  };
                } 
                nftcontainer.appendChild(btnPowerUp)
              } 
              DivContainer.appendChild(nftcontainer); 
            }
            catch (ex2) {
              ShowNFTError(nftName + " - Error on reading token URI");
              console.log('ShowNFTErrorCard', ex2);
            }

          })();
        }

        return balanceOfOwner;
      }
      catch (ex) {
        console.log('Error on ShowNFTCard', ex);
      }
    }

    async function UnstakeSelected() {
      ClaimThem(true);
    }

    async function ClaimSelected() {
      ClaimThem(false);
    }

    async function BackToCollection() { 
      await LoadVaults('staked-nft-container');
    }

    async function ClaimThem(unstake) {
      try {        
        if (account==null){
          return;
        }

        isLoading = true; 
        await onGoingNotif("Pending...");

        const DivContainer = document.getElementById('staked-nft-container');
        var children = DivContainer.getElementsByTagName('*');
        var elements = [], child;
        for (var i = 0; i < children.length; i++) {
          child = children[i];
          if (child.type == "checkbox" && child.checked == true) {
            elements.push(child);
          }
        }

        var tkIDs = [];
        var _vaultId;

        //loop for getting staked vault
        for (var j = 0; j < elements.length; j++) {
          var nft = [];
          nft = elements[j].id.split("_");
          _vaultId = await stakingnftcontract.methods.getVaultId(nft[0]).call();

          tkIDs.push(nft[1]);
        }

        if (unstake) {
          await stakingnftcontract.methods.unstake(_vaultId, tkIDs).send({ from: account });
        }
        else {
          await stakingnftcontract.methods.claim(_vaultId, tkIDs).send({ from: account });
        }
        BackToCollection();
        isLoading = false;

      }
      catch (ex) {
        console.log("ClaimThem - " + ex);
      }
      await onGoingNotif("");
    }

    async function addToken(tokenAddress, tokenSymbol, tokenDecimals, tokenImage){
      const wasAdded = await window.ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20', // Initially only supports ERC20, but eventually more!
          options: {
            address: tokenAddress, // The address that the token is at.
            symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
            decimals: tokenDecimals, // The number of decimals in the token
            image: tokenImage, // A string url of the token logo
          },
        },
      }); 
    }
  
    async function StakeAll() {      
      if (account==null){
        return;
      }

      const DivContainer = document.getElementById('unstaked-nft-container');
      var children = DivContainer.getElementsByTagName('*');
      var elements = [], child;
  
      for (var i = 0; i < children.length; i++) {
        child = children[i];
        if (child.type == "checkbox") {
          elements.push(child);
        }
      }

      if (elements.length == 0) {
        return;
      }

      var _isNFTAppr = false;
      var address;
      var tkIDs = [];

      var _reject = false;

      for (var i = 0; i < elements.length; i++) {
        var nft = [];
        nft = elements[i].id.replace("chk", "").split("_");

        if (_isNFTAppr == false) {
          address = nft[0];
          await onGoingNotif("Approve All");
          _isNFTAppr = await nftApproveAll(address);

          if (_isNFTAppr == false) {
            _reject = true;
            await onGoingNotif("");
            return;
          }
          await onGoingNotif("Pending...");
        }

        tkIDs.push(nft[1]);
      }

      if (await isWhitelist(address)) {
        var _vaultId = await stakingnftcontract.methods.getVaultId(address).call();
        await stakingnftcontract.methods.stake(_vaultId, tkIDs).send({ from: account });
      }
      else {
        await stakingnftcontract.methods.stakeAny(address, tkIDs, true).send({ from: account });
      }
      await refreshDAppView();
      await onGoingNotif("");


    }

    async function StakeThem() {
      try {        
        if (account==null){
          return;
        }
        await onGoingNotif("Pending...");

        const DivContainer = document.getElementById('unstaked-nft-container');
        var children = DivContainer.getElementsByTagName('*');
        var elements = [], child;
        for (var i = 0; i < children.length; i++) {
          child = children[i];
          if (child.type == "checkbox" && child.checked == true) {
            elements.push(child);
          }
        }

        var _isNFTAppr = false;
        var address;
        var tkIDs = [];

        var _reject = false;

        for (var i = 0; i < elements.length; i++) {
          var nft = [];
          nft = elements[i].id.replace("chk", "").split("_");

          if (_isNFTAppr == false) {
            address = nft[0];
              await onGoingNotif("");
              await onGoingNotif("Approve All");
            _isNFTAppr = await nftApproveAll(address);

            if (_isNFTAppr == false) {
              _reject = true;
              await onGoingNotif("");
              return;
            }
            await onGoingNotif("Pending...");
          }
          tkIDs.push(nft[1]);
        }

        if (await isWhitelist(address)) {
          var _vaultId = await stakingnftcontract.methods.getVaultId(address).call();
          await stakingnftcontract.methods.stake(_vaultId, tkIDs).send({ from: account });
        }
        else {
          await stakingnftcontract.methods.stakeAny(address, tkIDs, true).send({ from: account });
        }
        await refreshDAppView();
        await onGoingNotif("");
      }
      catch (ex) {
        console.log("StakeThem - " + ex);
      }
      await onGoingNotif("");

    }

    async function refreshDAppView() {
      await RefreshCollection();
      console.log(resultAPICall);
      await listNFTFilter(resultAPICall);
      //await stakeVaults('staked-nft-container');
      await LoadVaults('staked-nft-container');
      await onGoingNotif("");
    }

    async function nftApproveAll(address) {
      try {
        var _nftcontract = await ABIFetch(web3, ABI.ERC721.ABI, address);
        var _isAppr = await _nftcontract.methods.isApprovedForAll(account, stakingnft_address).call();
        if (_isAppr == false) {
          await _nftcontract.methods.setApprovalForAll(stakingnft_address, true).send({ from: account })
        }
        return true;
      }
      catch (ex) {
        console.log(address);
        console.log("nftApproveAll - " + ex);
        return false;
      }

    }

    async function isWhitelist(address) {
      var _res = false;
      try {
        for (var key in CONFIGWhitelist.NFT) {
          if (CONFIGWhitelist.NFT[key].ADDRESS == address) {
            _res = true;
            break;
          }
        }
      }
      catch (ex) {
        console.log("isWhitelist - " + ex);
      }
      return _res;
    }

    async function ShowNFTError(msgValue) {
      const DivContainer = document.getElementById('unstaked-nft-container');

      const curDiv = document.createElement('div');
      curDiv.className = "nftcontainer";  // mt-3 ml-3";

      const chk = document.createElement("INPUT");
      chk.setAttribute("type", "checkbox");
      chk.id = "chk";
      chk.className = "unlisted";

      const lbl = document.createElement("LABEL");
      lbl.htmlFor = chk.id;

      const objNFT = document.createElement('h6');
      objNFT.innerHTML = msgValue;
      objNFT.className = "errorMessage";

      curDiv.appendChild(chk);
      curDiv.appendChild(lbl);

      curDiv.appendChild(objNFT);
      DivContainer.appendChild(curDiv);
    }

    async function showCover() {
      let coverDiv = document.createElement('div');
      coverDiv.id = 'cover-div'; 

      // make the page unscrollable while the modal form is open
      document.body.style.overflowY = 'hidden';
      document.body.append(coverDiv);
    }

    async function SendNFT(nftAdd, destiAdd, tkID) {
      try {
        await onGoingNotif("Sending NFT...");

        nftcontract = await ABIFetch(web3, ABI.ERC721.ABI, nftAdd); 

        if (await nftcontract.methods.isApprovedForAll(account, stakingnft_address).call()==false){
          await nftcontract.methods.setApprovalForAll(stakingnft_address, true).send({ from: account});
        }

        await nftcontract.methods.safeTransferFrom(account, destiAdd, tkID).send({ from: account });
        await refreshDAppView();

        await onGoingNotif("");
      }
      catch (ex) {
        console.log(ex);
        await onGoingNotif("");
      }
    }

    async function NitroAdd() {
      await onGoingNotif("Pending...");
      isLoading = true;
      try {
        let DivContainer = document.getElementById('prompt-message');
        if (isMobile){
          DivContainer = document.getElementById('prompt-message-mobile');
        }
        var children = DivContainer.getElementsByTagName('*');
        var child;

        if (children.length > 0) {

          var _isAppr = await nitrocontract.methods.isApprovedForAll(account, stakingnft_address).call();
          if (_isAppr == false) {
            await nitrocontract.methods.setApprovalForAll(stakingnft_address, true)
              .send({
                from: account,
                gasLimit: String(totalGasLimit)
              })
          }

          for (var i = 0; i < children.length; i++) {
            child = children[i];
            if (child.type == "radio" && child.checked == true) {
              var nft = [];
              nft = child.id.split("|");

              var _vaultId = nft[0];
              var _tkID = nft[1];
              var _nitroId = nft[2]; 

              await stakingnftcontract.methods.stakeNitro(_vaultId, _tkID, _nitroId)
                .send({
                  from: account 
                });
              break;
            }
          }
        }
      }
      catch (ex) {
        console.log(ex);
      }
      await onGoingNotif("");
      isLoading = false;
    }

    async function hideCover() {
      try {
        document.getElementById('cover-div').remove();
        document.body.style.overflowY = '';
      }
      catch (ex) {
      }
    }

    async function showPromptTransfer(text, callback) {
      showCover();
 
      var _nft = [];
      _nft = text.split("|");
      var _vaultId = _nft[0];
 
      //Highlight the selected NFT
      document.getElementById('nft' + _nft[0] + '|' + _nft[1]).className="nftCard SelectedNFT";

      var _vault;
      if (_vaultId == -1) {
        nftcontract = await ABIFetch(web3, ABI.ERC721.ABI, _nft[2]);
      }
      else {
        _vault = await stakingnftcontract.methods.Vault(_vaultId).call();
        nftcontract = await ABIFetch(web3, ABI.ERC721.ABI, _vault.nftAddress);
      }

      var _nftName = await nftcontract.methods.name().call();

      
      let form = document.getElementById('prompt-form');
      if (isMobile){
        form = document.getElementById('prompt-form-mobile');
      }

      let container = document.getElementById('prompt-form-container');
      let msg = document.getElementById('prompt-message');
      if (isMobile){
        msg = document.getElementById('prompt-message-mobile');
      }

      var h6NFTAddy = document.createElement('h5');
      h6NFTAddy.className = "h6Nitro";
      form.className = "transfer-width";

      var txAdd;
      if (_vaultId == -1) {
        h6NFTAddy.innerHTML = _nftName + " #" + _nft[1] + "<br>" + _nft[2] + "<br>";
        txAdd = _nft[2];
      }
      else {
        h6NFTAddy.innerHTML = _nftName + " #" + _nft[1] + "<br>" + _vault.nftAddress + "<br>";
        txAdd = _vault.nftAddress;
      }

      var h6Caption = document.createElement('h6');
      h6Caption.className = "h6Nitro";
      h6Caption.innerHTML = "Transfer NFT to (Input Wallet Address)";

      var x = document.createElement("INPUT");
      x.id = text;
      x.placeholder = "0x0000000000000000000000000000000000000000";
      x.setAttribute("type", "text");

      var curDiv = document.createElement('div');
      curDiv.className = "transfer-nft";
      msg.className = "";
      msg.innerHTML = "";

      var btnSubmit = document.getElementById('btnSubmit');
      btnSubmit.value = "Send";

      curDiv.appendChild(h6NFTAddy);
      curDiv.appendChild(h6Caption);
      curDiv.appendChild(x);

      msg.appendChild(curDiv);


      function complete(value) {
        if (value == "submit") {
          SendNFT(txAdd, x.value, _nft[1]);
        }
        hideCover();
        container.style.display = 'none';
        document.onkeydown = null;
        callback(value);
      }

      form.onsubmit = function () {
        let value = "submit";
        document.getElementById('nft' + _nft[0] + '|' + _nft[1]).className="nftCard";
        complete(value);
        return false;
      };

      form.cancel.onclick = function () {
        document.getElementById('nft' + _nft[0] + '|' + _nft[1]).className="nftCard";
        complete(null);
      };

      document.onkeydown = function (e) {
        if (e.key == 'Escape') {
          document.getElementById('nft' + _nft[0] + '|' + _nft[1]).className="nftCard";
          complete(null);
        }
      };
      container.style.display = 'block';
    }

    async function showLoading() {
      let form = document.getElementById('prompt-form');
      let container = document.getElementById('prompt-form-container');

      let DivContainer = document.getElementById('prompt-message');

      showCover(); 
      DivContainer.innerHTML = "";

      const lbl = document.createElement("LABEL");
      lbl.className = "parent";

      let img = document.createElement('img');
      img.setAttribute("src", 'loading.gif');

      let curDiv = document.createElement('div');
      curDiv.className = "nitrocontainer";

      lbl.appendChild(img);
      curDiv.appendChild(lbl);
      DivContainer.appendChild(curDiv);

      container.style.display = 'block'; 
    }

    async function showPrompt(text, callback) {     
      let container = document.getElementById('prompt-form-container');
      let form;
      let DivContainer; 
      if(isMobile){
        form = document.getElementById('prompt-form-mobile');
        DivContainer = document.getElementById('prompt-message-mobile'); 
      }
      else{
        form = document.getElementById('prompt-form');
        DivContainer = document.getElementById('prompt-message'); 
      }

      var curDiv;
      var contractAdress;
      var balanceOfOwner = await nitrocontract.methods.balanceOf(account).call(); 

      showCover();

      var _nft = [];
      _nft = text.split("|");

      var lastClass = document.getElementById('nft' + _nft[0] + '|' + _nft[1]).className;
      document.getElementById('nft' + _nft[0] + '|' + _nft[1]).className="nftCard SelectedNFT"; 
      curDiv = document.createElement('div');
      contractAdress = await nitrocontract.methods.symbol().call();   
      DivContainer.innerHTML = ""; 

      for (let i = 0; i < balanceOfOwner; i++) {
        (async function () {
          try {
            var tkID = await nitrocontract.methods.tokenOfOwnerByIndex(account, i).call();

            var nitCard = document.createElement('div');            
            nitCard.id = "nit" + tkID;
            if(isMobile){
              nitCard.className = "nitCard-mobile";
            }
            else{
              nitCard.className = "nitCard";
            }

            var nitTable = document.createElement('TABLE');
            nitTable = document.createElement("TABLE");
            nitTable.setAttribute("id", "tbl" + tkID);
            nitCard.appendChild(nitTable);

            var nitTR = document.createElement('TR');
            nitTR.className = "nitTR";
            nitTable.appendChild(nitTR);

            var nitTD1 = document.createElement('TD');
            nitTD1.className = "nitTD1";
            nitTR.appendChild(nitTD1);
             
            var nitchk = document.createElement("INPUT");
            nitchk.setAttribute("type", "radio"); 
            nitchk.id = text + '|' + tkID;
            nitchk.className = "nitchk";
            nitchk.name = "nitro";
            nitTD1.appendChild(nitchk);

            var nitlbl = document.createElement("LABEL");
            nitlbl.htmlFor = nitchk.id;
            nitTD1.appendChild(nitlbl);


            let tokenMetadataURI = await nitrocontract.methods.tokenURI(tkID).call();
            if (tokenMetadataURI.startsWith("ipfs://")) {
              tokenMetadataURI = `https://ipfs.io/ipfs/${tokenMetadataURI.split("ipfs://")[1]}`;
            }
            else if (tokenMetadataURI.startsWith("https://gateway.pinata.cloud/ipfs/")) {
              tokenMetadataURI = tokenMetadataURI.replace(`https://gateway.pinata.cloud/ipfs/`, ``);
              tokenMetadataURI = `https://ipfs.io/ipfs/${tokenMetadataURI}${1}`;
            }
            var tokenMetadata = await fetch(tokenMetadataURI).then((response) => response.json()); 

            var imgNFT = document.createElement('img');
            imgNFT.setAttribute("src", tokenMetadata["image"]);  
            imgNFT.setAttribute("alt", contractAdress);
            imgNFT.setAttribute("title", "Nitro #" + tkID);
            imgNFT.style.width = "100px";
            nitlbl.appendChild(imgNFT);

            

            var nitTD2 = document.createElement('TD');
            nitTD2.className = "nitTD2";
            nitTR.appendChild(nitTD2);
            
            var nitAtt1 = document.createElement('h6');
            nitAtt1.className = "nitAtt";
            nitAtt1.innerHTML = tokenMetadata["attributes"][0]["trait_type"] + ': <b> x' + tokenMetadata["attributes"][0]["value"] + '</b>'; 
            nitTD2.appendChild(nitAtt1);

            var nitAtt2 = document.createElement('h6');
            nitAtt2.className = "nitAtt";
            nitAtt2.innerHTML = tokenMetadata["attributes"][1]["trait_type"] + ': ' + tokenMetadata["attributes"][1]["value"]; 
            nitTD2.appendChild(nitAtt2);

            var nitAtt3 = document.createElement('h6');
            nitAtt3.className = "nitAtt";
            nitAtt3.innerHTML = tokenMetadata["attributes"][2]["trait_type"] + ': ' + tokenMetadata["attributes"][2]["value"]; 
            nitTD2.appendChild(nitAtt3);
 
            DivContainer.appendChild(nitCard);

          }
          catch (ex2) {
            console.log(ex2);
          }

        })();
      }

      function complete(value) {
        if (value == "submit") {
          NitroAdd();
        }
        document.getElementById('nft' + _nft[0] + '|' + _nft[1]).className=lastClass;
        hideCover();
        container.style.display = 'none';
        document.onkeydown = null;
        callback(value);
      }

      form.onsubmit = function () {
        let value = "submit";
        document.getElementById('nft' + _nft[0] + '|' + _nft[1]).className=lastClass;
        complete(value);
        return false;
      };

      form.cancel.onclick = function () {
        document.getElementById('nft' + _nft[0] + '|' + _nft[1]).className=lastClass;
        complete(null);
      };

      document.onkeydown = function (e) {
        if (e.key == 'Escape') {
          document.getElementById('nft' + _nft[0] + '|' + _nft[1]).className=lastClass;
          complete(null);
        }
      };
      container.style.display = 'block';
    }

    async function NitroMint(pID) {
      try { 
        if (account==null){
          return;
        }
        await onGoingNotif("Pending...");

        if (amtNitro > 0) {

          if (pID != 5){ 
            var totalCost = await nitrocontract.methods.getNitroPrice(pID).call();
            totalCost = Web3.utils.fromWei(totalCost) * amtNitro;
            var totalCostWei = Web3.utils.toWei(String(totalCost));

            var _token = await nitrocontract.methods.getCryptotoken(pID).call();
            var _tokencontract = await ABIFetch(web3, ABI.TOKENREWARD.ABI, _token);
            var _allow_int = await _tokencontract.methods.allowance(account, nitroboost_address).call();
            var _allow = parseInt(_allow_int);

            var _txRejected = false;

            if (_allow < parseInt(totalCostWei)) {
              await onGoingNotif("Approval...");

              var apprAmt = Web3.utils.toWei(String(Number.MAX_SAFE_INTEGER));

              await _tokencontract.methods.approve(nitroboost_address, String(apprAmt))
                .send({ from: account })
                .on('error', function (error, receipt) {
                  _txRejected = true;
                })
                .catch(() => {
                  onGoingNotif("");
                });

              await onGoingNotif("Pending...");
            }
            if (_txRejected == false) {

              await nitrocontract.methods
                .mint(amtNitro, pID)
                .send({
                  gasLimit: String(totalGasLimit * amtNitro),
                  to: nitroboost_address,
                  from: account
                })
                .on('confirmation', (confirmationNumber, receipt) => {
                  onGoingNotif("");
                });
            }
          }
          else{

            var _gasLimit = String(totalGasLimit * amtNitro);
            var _cost = await nitroBCHcontract.methods.cost().call();
            var _totalCost = String(Number(_cost) * amtNitro);
            console.log("totalCost", typeof(totalCost));
            /* var totalCostWei = Web3.utils.toWei(String(totalCost)); */

            nitroBCHcontract.methods.mint(amtNitro)
            .send({
              gasLimit: _gasLimit,
              to: nitroBCH_address,
              from: account,
              value: _totalCost 
            })



            /* let _nitroBCHPrice = Number(nitroBCH);

            //totalCost = String(Web3.utils.fromWei(_nitroBCHPrice) * amtNitro);
            totalCost = _nitroBCHPrice * amtNitro;
            console.log("totalCost", totalCost);

            var totalCostWei = Web3.utils.toWei(String(totalCost)); 
            console.log("totalCostWei", totalCostWei);

            await nitrocontract.methods
                .mint(amtNitro, pID)
                .send({
                  gasLimit: String(totalGasLimit * amtNitro),
                  to: nitroboost_address,
                  from: account,
                  value: totalCostWei
                });
            */
          }
 

        }
      }
      catch (ex) {
        console.log(ex);
      }
      await onGoingNotif("");
    }

    async function BtnSelected(amt) {
      amtNitro = amt;
    }
 
    if (isMobile) { 
      return (
        <div className="App nftapp">
          <nav class="navbar navbarfont navbar-expand-md navbar-dark bg-dark mb-1" >
            <div class="container-fluid" style={{ fontFamily: "SF Pro Display" }}>
              <a class="navbar-brand" style={{ fontWeight: "800", fontSize: '15px' }} href="#">
                <img src="logo.png" width="50" />
              </a>
              <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
              </button>
              <div class="collapse navbar-collapse" id="navbarCollapse">
                <ul class="navbar-nav me-auto mb-2 px-3 mb-md-0" style={{ fontSize: "15px" }}>
                  <li class="nav-item">
                    <a class="nav-link active" aria-current="page" href="#">Dashboard</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="#">Market Place</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link">Bridge NFTs</a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>

          <div className='container container-style-mobile mb-5'>
            <div className='row'>
              <body className='nftminter-mobile'>
                <form>
                  <div className='row p-2'>
                    <h5 className='h5Nitro'>Mint Nitro NFT Here</h5>
                  </div>
                  <img src="./nitrocommon.gif" width="100px" />
                  <h6 className='h6Nitro'>Select Nitro Quantity</h6>

                  <ButtonGroup size="lg"
                    aria-label="First group"
                    name="amount"
                    style={{ boxShadow: "1px 1px 5px #000000" }}
                    class="btn-group"
                  >
                    <Button value="1" onClick={function () { BtnSelected(1) }}>1</Button>
                    <Button value="2" onClick={function () { BtnSelected(2) }}>2</Button>
                    <Button value="3" onClick={function () { BtnSelected(3) }}>3</Button>
                    <Button value="4" onClick={function () { BtnSelected(4) }}>4</Button>
                    <Button value="5" onClick={function () { BtnSelected(5) }}>5</Button>
                  </ButtonGroup>

                  <h6 className='h6Nitro mt-3'>Pay with your preferred Crypto</h6>
                  <div className="nitro-qty" role="group" id="btnNitroQty" aria-label="Token Payment" >
                  <img src={"./tokens/1.png"} width="50" alt="CrudeOil" class="btn btn-primary" className='btnpayment ml-1' id="btnCrudeOil" onClick={function () { NitroMint(0) }} />
                    <img src={"./tokens/2.png"} width="50" alt="MartinB" class="btn btn-primary" className='btnpayment ml-1' id="btnMartinB" onClick={function () { NitroMint(1) }} />
                    <img src={"./tokens/3.png"} width="50" alt="KTH" class="btn btn-primary" className='btnpayment ml-1' id="btnKTH" onClick={function () { NitroMint(2) }} />
                    <img src={"./tokens/4.png"} width="50" alt="ARG" class="btn btn-primary" className='btnpayment ml-1' id="btnARG" onClick={function () { NitroMint(3) }} />
                    <img src={"./tokens/5.png"} width="50" alt="Tango" class="btn btn-primary" className='btnpayment ml-1' id="btnTango" onClick={function () { NitroMint(4) }} />
                    <img src={"./tokens/6.png"} width="50" alt="BCH" class="btn btn-primary" className='btnpayment ml-1' id="btnBCH" onClick={function () { NitroMint(5) }} />
                  </div>
                </form>
              </body>              
            </div>
            <div className='row'>
              <body className='nftstaker'>
                <form style={{ fontFamily: "SF Pro Display" }} >
                  <Tabs defaultActiveKey="second" className="nav-pills nav-fill">
                    <Tab eventKey="first" title="Unstaked NFT">
                      <div className='row items' >
                        <div class="btn-toolbar justify-content-between" role="toolbar" aria-label="Toolbar with button groups">
                          <div class="hold-nft-menu buttonmenu" role="group" aria-label="Hold NFT Menu">
                            <button type="button" style={{ fontSize: "9px" }} class="btn btn-secondary" id="btnStakeSelected" onClick={StakeThem}>Stake Selected</button>
                            <button type="button" style={{ fontSize: "9px" }} class="btn btn-secondary" id="btnStakeAll" onClick={StakeAll}>Stake All</button>
                            <select id="nftFilter" style={{ fontSize: "9px" }} className='drpFilter btn-secondary' onChange={RefreshCollection}>
                              <option selected class="selected">JeepneyRushNFT</option>
                              <option>All Owned NFT</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className='col'>
                        <div className='row items'>
                          <div id='unstaked-nft-container' className='nft_card_view-mobile' />
                        </div>
                      </div>
                    </Tab>
                    <Tab eventKey="second" title="Staked NFT">
                      <div className='row items' >
                        <div class="btn-toolbar justify-content-between" role="toolbar" aria-label="Toolbar with button groups">
                          <div class="hold-nft-menu buttonmenu" id="btnUnstakeMenu" role="group" aria-label="Stake NFT Menu">
                            <button type="button" style={{ fontSize: "9px" }} class="btn btn-secondary" id="btnBack" onClick={BackToCollection}>Back</button>
                            <button type="button" style={{ fontSize: "9px" }} class="btn btn-secondary" id="btnClaimSelected" onClick={ClaimSelected}>Claim Selected</button>
                            <button type="button" style={{ fontSize: "9px" }} class="btn btn-secondary" id="btnUnstakeSelected" onClick={UnstakeSelected}>Unstake Selected</button>
                          </div>
                        </div>
                      </div>
                      <div className='row items'>
                        <div id='staked-nft-container' className='ml-3 mr-3 nft_card_view' />
                      </div>
                    </Tab>
                  </Tabs>
                </form>
              </body>
            </div>
          </div>
 
          <div className="row px-4 pt-2">
            <div className='header' style={{ fontSize: '11px' }}>
              <div style={{ fontSize: '15px', borderRadius: '14px', color: "#ffffff", fontWeight: "300" }}>Staking Vault Rewards</div>
              <table id="tblvaultInfo" className='table px-15 table-bordered table-dark' />
            </div>
          </div>

          <nav class="navbar navbarfont bg-dark"/*navbar-expand-md navbar-dark  style={{ marginBottom: "0px", marginLeft: "0px", marginRight: "0px", clear: "both" }} */>
            <div class="navbar-inner">
              <div class="d-flex flex-row accountInfo" style={{marginLeft: "10px"}}>
                <div id="wallBal" className="walletBalance" />
                <div id="btnConnect" onClick={connectwallet} className="connectbutton ml-2" type="button" style={{ fontFamily: "SF Pro Display" }}>
                  Connect Your Wallet
                </div>
              </div>

            </div>
          </nav>

          <div id="prompt-form-container">
            <form id="prompt-form-mobile">
              <div className='add-booster'>
                <div id="prompt-message-mobile"></div>
                <input name="btnSubmit" id="btnSubmit" type="submit" value="Equip"></input>
                <input name="cancel" id="cancel" type="button" value="Cancel" />
              </div>
            </form>
          </div>

        </div>
      );
    }
    else { 
      return (
        <div className="App nftapp">
          <nav class="navbar navbarfont navbar-expand-md navbar-dark bg-dark mb-4">
            <div class="container-fluid" style={{ fontFamily: "SF Pro Display" }}>
              <a class="navbar-brand px-5" style={{ fontWeight: "800", fontSize: '15px' }} href="#"></a><img src="logo.png" width="7%" />
              <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
              </button>
              <div class="collapse navbar-collapse" id="navbarCollapse">
                <ul class="navbar-nav me-auto mb-2 px-3 mb-md-0" style={{ fontSize: "15px" }}>
                  <li class="nav-item">
                    <a class="nav-link active" aria-current="page" href="#">Dashboard</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="#">Market Place</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link">Bridge NFTs</a>
                  </li>
                </ul>
              </div>
            </div> 
            <div className='px-5'>
              <div class="d-flex flex-row accountInfo">
                <div id="wallBal" className="walletBalance" />
                <div id="btnConnect" onClick={connectwallet} className="connectbutton ml-2" type="button" style={{ fontFamily: "SF Pro Display" }}>
                  Connect Your Wallet
                </div>
              </div>
            </div>
          </nav>

          <div className='container container-style'>
            <div className='col'>
              <body className='nftminter'>
                <form>
                  <div className='row p-2'>
                    <h5 className='h5Nitro'>Mint Nitro NFT Here</h5>
                  </div>
                  <img src="./nitrocommon.gif" width="100px" />
                  <h6 className='h6Nitro'>Select Nitro Quantity</h6>

                  <ButtonGroup size="lg"
                    aria-label="First group"
                    name="amount"
                    style={{ boxShadow: "1px 1px 5px #000000" }}
                    class="btn-group"
                  >
                    <Button value="1" onClick={function () { BtnSelected(1) }}>1</Button>
                    <Button value="2" onClick={function () { BtnSelected(2) }}>2</Button>
                    <Button value="3" onClick={function () { BtnSelected(3) }}>3</Button>
                    <Button value="4" onClick={function () { BtnSelected(4) }}>4</Button>
                    <Button value="5" onClick={function () { BtnSelected(5) }}>5</Button>
                  </ButtonGroup>

                  <h6 className='h6Nitro mt-3'>Pay with your preferred Crypto</h6>
                  <div class="nitro-qty" role="group" id="btnNitroQty" aria-label="Token Payment">
                    <img src={"./tokens/1.png"} width="50" alt="CrudeOil" class="btn btn-primary" className='btnpayment ml-1' id="btnCrudeOil" onClick={function () { NitroMint(0) }} />
                    <img src={"./tokens/2.png"} width="50" alt="MartinB" class="btn btn-primary" className='btnpayment ml-1' id="btnMartinB" onClick={function () { NitroMint(1) }} />
                    <img src={"./tokens/3.png"} width="50" alt="KTH" class="btn btn-primary" className='btnpayment ml-1' id="btnKTH" onClick={function () { NitroMint(2) }} />
                    <img src={"./tokens/4.png"} width="50" alt="ARG" class="btn btn-primary" className='btnpayment ml-1' id="btnARG" onClick={function () { NitroMint(3) }} />
                    <img src={"./tokens/5.png"} width="50" alt="Tango" class="btn btn-primary" className='btnpayment ml-1' id="btnTango" onClick={function () { NitroMint(4) }} />
                    <img src={"./tokens/6.png"} width="50" alt="BCH" class="btn btn-primary" className='btnpayment ml-1' id="btnBCH" onClick={function () { NitroMint(5) }} />
                  </div>

                  <br></br>
                  <div className="row pt-2">
                    <div className='header' style={{ fontSize: '11px' }}>
                      <div style={{ fontSize: '15px', borderRadius: '14px', color: "#ffffff", fontWeight: "300" }}>Nitro NFT Rates</div>
                      <table id="tblnitroInfo" className='table table-bordered table-dark' >
                        <thead className='thead-light'>
                          <tr>
                            <th scope="col">Type</th>
                            <th scope="col">Price</th>
                            <th scope="col">Reward Rate</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Nitro Crude</td>
                            <td>700 OIL</td>
                            <td>x2.0</td>
                          </tr>
                          <tr>
                            <td>Nitro Martin</td>
                            <td>8,181,050,000 Martin₿</td>
                            <td>x2.1</td>
                          </tr>
                          <tr>
                            <td>Nitro KTH</td>
                            <td>58,821.8 KTH</td>
                            <td>x2.2</td>
                          </tr>
                          <tr>
                            <td>Nitro ARG</td>
                            <td>112,126,000 ARG</td>
                            <td>x2.2</td>
                          </tr>
                          <tr>
                            <td>Nitro Tango</td>
                            <td>133.277 Tango</td>
                            <td>x2.3</td>
                          </tr>
                          <tr>
                            <td>Nitro BCH</td>
                            <td>0.007923 BCH</td>
                            <td>x2.4</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='header' style={{ fontSize: '11px' }}>
                      <div style={{ fontSize: '15px', borderRadius: '14px', color: "#ffffff", fontWeight: "300" }}>Staking Vault Rewards</div>
                      <table id="tblvaultInfo" className='table px-5 table-bordered table-dark' />
                    </div>
                  </div>
                  
                </form>
              </body>

            </div>
            <div className='col'>
              <table className='nftstaker'>
                <tr>
                  <body className='nftstaker'>
                    <form style={{ fontFamily: "SF Pro Display" }} >
                      <Tabs defaultActiveKey="second" className="nav-pills nav-fill">
                        <Tab eventKey="first" title="Unstaked NFT">
                          <div className='row items' >
                            <div class="btn-toolbar justify-content-between" role="toolbar" aria-label="Toolbar with button groups">
                              <div class="hold-nft-menu buttonmenu" role="group" aria-label="Hold NFT Menu">
                                <button type="button" class="btn btn-secondary" id="btnStakeSelected" onClick={StakeThem}>Stake Selected</button>
                                <button type="button" class="btn btn-secondary" id="btnStakeAll" onClick={StakeAll}>Stake All</button>
                                <select id="nftFilter" className='drpFilter btn-secondary' onChange={RefreshCollection}>
                                  <option selected class="selected">JeepneyRushNFT</option>
                                  <option>All Owned NFT</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className='col'>
                            <div className='row items'>
                              <div id='unstaked-nft-container' className='nft_card_view' />
                            </div>
                          </div>
                        </Tab>
                        <Tab eventKey="second" title="Staked NFT">
                          <div className='row items' >
                            <div class="btn-toolbar justify-content-between" role="toolbar" aria-label="Toolbar with button groups">
                              <div class="hold-nft-menu buttonmenu" id="btnUnstakeMenu" role="group" aria-label="Stake NFT Menu">
                                <button type="button" class="btn btn-secondary" id="btnBack" onClick={BackToCollection}>Back</button>
                                <button type="button" class="btn btn-secondary" id="btnClaimSelected" onClick={ClaimSelected}>Claim Selected</button>
                                <button type="button" class="btn btn-secondary" id="btnUnstakeSelected" onClick={UnstakeSelected}>Unstake Selected</button>
                              </div>
                            </div>
                          </div>
                          <div className='row items'>
                            <div id='staked-nft-container' className='ml-3 mr-3 nft_card_view' />
                          </div>
                        </Tab>
                      </Tabs>
                    </form>
                  </body>
                </tr>
              </table>

            </div>
          </div>

          <div id="prompt-form-container"> 
            <form id="prompt-form">
              <div className='add-booster'>
                <div id="prompt-message" className='scroll'></div>
                <input name="btnSubmit" id="btnSubmit" type="submit" value="Equip"></input>
                <input name="cancel" id="cancel" type="button" value="Cancel" />
              </div>
            </form>
          </div>

          <footer>
            <div class="container">
              <div class="row secondary"></div>
            </div>
            <div class="row copyright">
              <p>Copyright &copy; 2022 RushDapp SmartBCH | All Rights Reserved
              </p> 
            </div>

          </footer>
        </div>
      );
    }

  };
}

export default App;